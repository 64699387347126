<template>
  <!--begin::Invoice Listing-->
  <div class="invoice-template">
    <PageHeaderCount
      moduleType="invoice"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_invoices"
      countkey="invoice_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'invoice-listing'"
      v-if="getPermission('invoice:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 mxw-200 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('invoice:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                          v-if="more.isActive"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_invoices
                          }}</template>
                          <template v-else>{{
                            item.invoice_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex class="pt-0 pr-0 justify-flex-end d-flex">
                <template v-if="getPermission('invoice:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="$router.push(getDefaultRoute('invoice.create'))"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false && !isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'invoice',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button pr-0"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          v-on:change="updateVisible()"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <v-menu offset-y left v-if="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entityObject.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PageTips v-if="false" module="invoice"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Invoice"
          :basicSearchFields="[
            'barcode',
            'reference',
            'job_title',
            'sales',
            'prepared_by',
            'attention',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="customer-listing-row invoice-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            :disabled="data.status == 5"
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode :barcode="data[cols.field]"></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'basic_detail'">
                          <div class="invoice-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Invoice Title: </b>
                              <template v-if="data.title">
                                {{ data.title }}
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Title</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Reference: </b>
                              <template v-if="data.reference">{{
                                data.reference
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Reference</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Payment Due: </b>
                              <template v-if="lodash.isEmpty(data.payment_due)"
                                ><em class="text--secondary"
                                  >No Payment Due</em
                                ></template
                              >
                              <template v-else>{{
                                data.payment_due.text
                              }}</template>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'dates'">
                          <div class="invoice-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Due Date: </b>
                              <template v-if="data.due_date">{{
                                formatDate(data.due_date)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Due Date</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Sent Date: </b>
                              <template v-if="data.sent_date">{{
                                formatDate(data.sent_date)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Sent Date</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Issued Date: </b>
                              <template v-if="data.invoice_date">{{
                                formatDate(data.invoice_date)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Issued Date</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'customer_info'">
                          <div class="invoice-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              <template
                                v-if="
                                  data &&
                                  data.customer &&
                                  data.customer.display_name
                                "
                              >
                                {{ data.customer.display_name }}
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Display Name</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>
                              <template
                                v-if="
                                  data &&
                                  data.customer &&
                                  data.customer.company_name
                                "
                              >
                                {{ data.customer.company_name }}
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Company Name</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <template
                                v-if="
                                  data &&
                                  data.billing_contact_person &&
                                  data.billing_contact_person.primary_email
                                "
                              >
                                {{ data.billing_contact_person.primary_email }}
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Email</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template
                                v-if="
                                  data &&
                                  data.billing_contact_person &&
                                  data.billing_contact_person.primary_phone
                                "
                              >
                                {{
                                  data.billing_contact_person &&
                                  data.billing_contact_person.primary_phone
                                }}
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Phone</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'contact_person'">
                          <div class="invoice-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              <template
                                v-if="
                                  data &&
                                  data.billing_contact_person &&
                                  data.billing_contact_person.display_name
                                "
                              >
                                {{ data.billing_contact_person.display_name }}
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Display Name</em
                                ></template
                              >
                            </p>

                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <template
                                v-if="
                                  data &&
                                  data.billing_contact_person &&
                                  data.billing_contact_person.primary_email
                                "
                              >
                                {{ data.billing_contact_person.primary_email }}
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Email</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template
                                v-if="
                                  data &&
                                  data.billing_contact_person &&
                                  data.billing_contact_person.primary_phone
                                "
                              >
                                {{ data.billing_contact_person.primary_phone }}
                              </template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Phone</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'status'">
                          <CustomStatus
                            small
                            :status="data.status"
                            endpoint="invoice/status"
                          ></CustomStatus>

                          <v-chip
                            v-if="data?.visit_converted == 1"
                            label
                            small
                            color="orange"
                            text-color="white"
                            class="my-2 ml-2 text-uppercase font-weight-600"
                            >Converted to Visit
                          </v-chip>
                        </template>
                        <template v-else-if="cols.field == 'amount'">
                          <div class="invoice-listing-amount">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Total : </b>
                              <label class="m-0 orange--text">{{
                                $accountingJS.formatMoney(data.total)
                              }}</label>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Paid: </b>
                              <label class="m-0 green--text">{{
                                $accountingJS.formatMoney(data.paid_amount)
                              }}</label>
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.pending_amount > 0"
                            >
                              <b>Pending: </b>
                              <label class="m-0 red--text">{{
                                $accountingJS.formatMoney(data.pending_amount)
                              }}</label>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="9" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no invoice at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Invoice</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Invoice')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>

        <template v-if="entityDialog">
          <EntityDialog
            :entity-dialog="entityDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectEntity="selectEntity"
          ></EntityDialog>
        </template>
        <template v-if="entity && customerDialog">
          <CustomerDialog
            :entity="entity"
            :customerDialog="customerDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="customerPersonDialog">
          <CustomerPersonDialog
            disabled-auto-select
            :customerPersonDialog="customerPersonDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          ></CustomerPersonDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            disabled-auto-select
            :customerPropertyDialog="customerPropertyDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
        <CreatePaymentInvoices
          :customer="customerSelected"
          :invoiceSelected="selectedRows"
          :dialog="paymentDialog"
          v-on:close="paymentDialog = false"
          v-on:success="getRows()"
          v-if="paymentDialog && selectedRows.length > 0"
        ></CreatePaymentInvoices>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Invoice Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import InvoiceMixin from "@/core/lib/invoice/invoice.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CreatePaymentInvoices from "@/view/pages/invoice/Create-Payment-Invoices.vue";

export default {
  mixins: [CommonMixin, ListingMixin, InvoiceMixin],
  name: "invoice-list",
  data() {
    return {
      paymentDialog: false,
      exportLoading: false,
      dates: [],
      pageModule: "invoice-listing",
      routeAPI: "invoice",
      routeName: "invoice",
      routeDetailName: "invoice.detail",
      status: "all",
      statusList: [],
      customThead: [],
      customerSelected: null,
      selectedInvoices: [],
      moreActions: [
        /*{
          title: "Export Invoice(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        /* {
          title: "Mark as Draft",
          icon: "mdi-check-all",
          action: "draft",
        },
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        }, */
        {
          title: "Mark as Paid",
          icon: "mdi-check-all",
          action: "mark_as_paid",
          isActive: true,
        },

        /* {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
          isActive: true
        },
          {
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        }, */
        /*{
          title: "Send as Email",
          icon: "mdi-email-send",
          action: "sent"
        },*/
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
          isActive: true,
        },
        /*{
          title: "Make Payment",
          icon: "mdi-check-all",
          action: "make_payment",
          isActive: true,
        },*/
        /*  {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        }, */
      ],
    };
  },
  components: {
    PageTips,
    draggable,
    EntityDialog,
    CustomStatus,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomerDialog,
    CustomerPropertyDialog,
    CustomerPersonDialog,
    CreatePaymentInvoices,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
    selectedRows: {
      deep: true,
      immediate: true,
      handler(param) {
        this.openMarkAsPaidOption(param);
      },
    },
  },
  methods: {
    openMarkAsPaidOption(selectedInvoice) {
      if (selectedInvoice.length < 1) {
        return false;
      }
      this.selectedInvoices = this.rowData.filter((invoice) =>
        selectedInvoice.includes(invoice.id)
      );
      const { customer: initialCustomer } = this.selectedInvoices[0];
      const isPaidOptionVisible = this.selectedInvoices.every(
        ({ customer, status }) =>
          customer.id === initialCustomer.id && status === 4
      );
      const updatedBulkActions = this.bulkActions.map((action) => {
        if (action.action === "mark_as_paid" && !isPaidOptionVisible) {
          return { ...action, isActive: false };
        } else {
          return { ...action, isActive: true };
        }
      });
      this.customerSelected = initialCustomer;

      this.bulkActions = updatedBulkActions;
    },

    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 1;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkInvoice();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkInvoice();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkInvoice();
    },
    checkInvoice() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("invoice.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          break;
        case "export_items":
          _this.bulkInvoiceExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("invoice");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    makePayment() {
      this.$router.push(
        this.getDefaultRoute("payment.create", {
          query: { invoices: this.selectedRows },
        })
      );
    },
    updateBulkAction(param) {
      const _this = this;
      switch (param) {
        case "make_payment":
          _this.makePayment();
          break;
        case "draft":
          _this.bulkInvoiceUpdate({
            invoices: _this.selectedRows,
            status: 1,
          });
          break;
        case "accepted":
          _this.bulkInvoiceUpdate({
            invoices: _this.selectedRows,
            status: 2,
          });
          break;
        case "rejected":
          _this.bulkInvoiceUpdate({
            invoices: _this.selectedRows,
            status: 6,
          });
          break;
        case "pending":
          _this.bulkInvoiceUpdate({
            invoices: _this.selectedRows,
            status: 4,
          });
          break;
        case "sent":
          _this.bulkInvoiceUpdate({
            invoices: _this.selectedRows,
            status: 5,
          });
          break;
        case "cancelled":
          _this.bulkInvoiceUpdate({
            invoices: _this.selectedRows,
            status: 5,
          });
          break;
        case "closed":
          _this.bulkInvoiceUpdate({
            invoices: _this.selectedRows,
            status: 7,
          });
          break;
        case "mark_as_paid":
          _this.paymentDialog = true;
          break;
      }
    },
    bulkInvoiceUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "invoice",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkInvoiceExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "invoices/export",
        })
        .then(({ data }) => {
          saveAs(data, "invoices-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.resetAll();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Invoice",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
